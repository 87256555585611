.work {
    color: inherit;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &__items {
        display: flex;
        flex-wrap: wrap;
        margin-left: 2rem;
    }

    &__item {
        padding: 2rem 3rem;
        margin: 0.2rem;
        background-color: rgba(white, 0.2);
        flex: 1;
        min-width: 40rem;
        position: relative;
        flex-grow: 1;
        transition: all 0.5s ease-in-out;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 0.6rem;
            transition: inherit;
        }

        &__date {
            font-style: italic;
            line-height: 3em;
        }

        &__place {
            top: 0;
            right: 0;
            float: right;
            margin: 1rem;
            shape-outside: margin-box;
        }

        &:hover {
            // background-color: rgba(white, 0.3);
            &::before {
                width: 25%;
                background-color: var(--color-secondary-light);
            }
        }
    }

    &__timeline {
        width: 0.6rem;
        height: 100%;
        background-color: rgba(white, 0.1);
        position: absolute;

        &__bar {
            position: absolute;
            width: 0.6rem;
            float: top;
            background-color: var(--color-secondary-light);
            transition: all 1s ease-out;
            border-top: 0.2rem solid var(--background-color);
            border-bottom: 0.2rem solid var(--background-color);
        }

        &__now {
            position: absolute;
            top: 0;
            right: 1.5rem;
        }

        &__start {
            position: absolute;
            bottom: 0;
            right: 1.5rem;
        }

        &__subbar {
            position: absolute;
            float: top;
            width: 0.6rem;
            background-color: white;
            border-top: 0.2rem solid var(--background-color);
            border-bottom: 0.2rem solid var(--background-color);
        }
    }
}


@media only screen and (max-width: $bp-small) {
    .work {
        &__items {
            margin-left: 1rem;
        }

        &__item {
            min-width: 30rem;
        }

        &__timeline {
            &__now {
                position: absolute;
                top: -3rem;
                left: 0;
            }

            &__start {
                position: absolute;
                bottom: -3rem;
                left: 0;
            }
        }

    }
}  