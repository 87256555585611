.projects {
    margin: auto;

    &__cards_container {
        display: flex;
        flex-wrap: wrap;
    }
    &__card {
        position: relative;
        margin: 2px;
        flex: 1;
        min-width: 30rem;
        max-width: 50rem;
        min-height: 25rem;
        background-color: rgba(white, 0.1);
        padding: 2rem 3rem;
        transition: all 0.5s ease-in-out;
        text-decoration: none;
        color: inherit;

        & h3 {
            font-size: 2rem;
            line-height: 1.3em;
            text-transform: uppercase;
        }

        &__keywords {
            top: 0;
            right: 0;
            text-align: right;

            float: right;
            height: 3rem;
            width: 10rem;
            shape-outside: margin-box;
        }

        &__label {
            font-size: 1.1rem;
            background-color: var(--color-grey-light-2);
            padding: 0.2rem 0.8rem;
            border-radius: 0.5rem;
            display: inline-block;
            margin-left: 0.5rem;
        }

        &__link {
            color: var(--color-grey-dark-1);

            &:hover {
                background-color: var(--color-grey-light-2);
            }
        }

        &:hover {
            background-color: rgba(white, 0.3);
        }
    }
}